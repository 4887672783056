var Carousel = function Carousel (container) {
	var DELAY = 8000;

	var imagesElement = container.getElementsByClassName('images')[0];
	var images = imagesElement.getElementsByTagName('img');
	for (var i = 0, ii = images.length; i < ii; i++) {
		images[i].style.left = (100 * i) + "%";
	}

	var timeoutId = 0;
	var currentImageIndex = 0;
	var move = function (number) {
		if (checkVisible(container)) {
			var nImages = images.length;
			currentImageIndex = ( ( (currentImageIndex + number) % nImages ) + nImages ) % nImages;

			var transformString 
				= 'translate3d('+ (currentImageIndex * -100) +'%'
				+ ', 0, 0)'
				;

			setVendorCss(imagesElement, 'transform', transformString);
		}

		window.clearInterval(timeoutId);
		timeoutId = window.setInterval(function () {
			move(1);
		}, DELAY);
	};
	
	move(0);

	container.getElementsByClassName('direction-control-left')[0]
		.onclick = function () {
			move(-1);
		}
	container.getElementsByClassName('direction-control-right')[0]
		.onclick = function () {
			move(1);
		}

};
