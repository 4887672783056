var getTotalElementOffset = function (element) {
	var cs = window.getComputedStyle(element);
	return { 
		vertical: element.offsetHeight
			+ window.parseInt(cs.marginTop) 
			+ window.parseInt(cs.marginBottom)

		, horizontal: element.offsetWidth
			+ window.parseInt(cs.marginLeft) 
			+ window.parseInt(cs.marginRight)
		};
};

var setVendorCss = function (element, property, value) {
	element.style[property] = value;
	element.style["webkit" + property] = value;
	element.style["moz" + property] = value;
	element.style["ms" + property] = value;
	element.style["o" + property] = value;
};

// underscore.js
var debounce = function (func, wait, immediate){
	var timeout, args, context, timestamp, result;
	if (null == wait) wait = 100;

	function later() {
		var last = Date.now() - timestamp;

		if (last < wait && last > 0) {
			timeout = setTimeout(later, wait - last);
		} else {
			timeout = null;
			if (!immediate) {
				result = func.apply(context, args);
				context = args = null;
			}
		}
	};

	var debounced = function(){
		context = this;
		args = arguments;
		timestamp = Date.now();
		var callNow = immediate && !timeout;
		if (!timeout) timeout = setTimeout(later, wait);
		if (callNow) {
			result = func.apply(context, args);
			context = args = null;
		}

		return result;
	};

	debounced.clear = function() {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	};

	return debounced;
};

function throttle (func, wait, immediate) {
	var ctx, args, rtn, timeoutID; // caching
	var last = 0;

	if (immediate) call();

	return function throttled () {
		ctx = this;
		args = arguments;
		var delta = new Date() - last;
		if (!timeoutID)
			if (delta >= wait) call();
			else timeoutID = setTimeout(call, wait - delta);
		return rtn;
	};

	function call () {
		timeoutID = 0;
		last = +new Date();
		rtn = func.apply(ctx, args);
		ctx = null;
		args = null;
	}
}

//http://stackoverflow.com/questions/5353934/check-if-element-is-visible-on-screen
var checkVisible = function(elm) {
	var rect = elm.getBoundingClientRect();
	var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
	return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

//options: wait, attempts, stateFn, success, fail 
var getStateChangedHandler = function (options) {
	if (!options['state']) return;
	if (!options['success']) return;

	return (function(){
		var _wait = options['wait'] || 100;
		var _maxAttempts = options['attempts'] || Infinity;
		var _stateFn = options['state'];
		var _success = options['success'];
		var _fail = options['fail'];
		
		var _attemptsLeft = _maxAttempts;
		var _intervalId, _lastState;
		return function(){
			_lastState = _stateFn();
			_intervalId = setInterval(function(){
				if (_stateFn(_lastState) == _lastState ) {
					if (--_attemptsLeft) return;
					if (_fail) _fail();
					clearTimeout(_intervalId);
					_attemptsLeft = _maxAttempts;
					return;
				}
				_success();
				clearTimeout(_intervalId);
			}, _wait);
		};
	}());
};